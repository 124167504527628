import { Actions, ActionTypes } from './actions';
import { featureAdapter, initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case ActionTypes.LOAD_DETAILS_SUCCESS:
      return {
        ...state,
        couponDetailsData: action.payload.couponData,
        isLoading: false,
        error: null,
      };

    case ActionTypes.LOAD_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case ActionTypes.UPDATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }
    case ActionTypes.UPDATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case ActionTypes.CREATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.CREATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
      };
    }

    case ActionTypes.CREATE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.DELETE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.DELETE_SUCCESS: {
      const { couponId } = action.payload;
      return featureAdapter.removeOne(couponId, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.DELETE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }

    case ActionTypes.LOAD_SUCCESS: {
      const { items } = action.payload;
      return featureAdapter.setAll(items, {
        ...state,
        isLoading: false,
        error: null,
      });
    }

    case ActionTypes.LOAD_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    case ActionTypes.RESET_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
