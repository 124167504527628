import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { Coupon } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getIsCouponDetails = (state: State): Coupon =>
  state.couponDetailsData;

export const selectCouponState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('coupons');

export const selectAllCouponsItems: (
  state: object,
) => Coupon[] = featureAdapter.getSelectors(selectCouponState).selectAll;

export const selectCouponById = (id: string) =>
  createSelector(selectAllCouponsItems, (coupons: Coupon[]) => {
    if (coupons) {
      return coupons.find((p) => p.id === +id);
    } else {
      return null;
    }
  });

export const selectCouponsError: MemoizedSelector<object, any> = createSelector(
  selectCouponState,
  getError,
);

export const selectCouponsIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectCouponState, getIsLoading);

export const selectCouponsDetails: MemoizedSelector<
  object,
  Coupon
> = createSelector(selectCouponState, getIsCouponDetails);
