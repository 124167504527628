import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { CouponsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as featureActions from './actions';
import { Coupon } from '../../models';

@Injectable()
export class CouponsStoreEffects {
  constructor(
    private dataService: CouponsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private _notifications: NotificationService,
    private _translate: TranslateService,
    private _router: Router,
  ) {}

  loadDetailsRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadDetailsRequestAction>(
        featureActions.ActionTypes.LOAD_DETAILS_REQUEST,
      ),
      switchMap((action: featureActions.LoadDetailsRequestAction) => {
        const { propertyId, couponId } = action.payload;
        return this.dataService.loadDetails(propertyId, couponId).pipe(
          map(({ data: [couponData] }: IResponseSuccess<Coupon[]>) => {
            return new featureActions.LoadDetailsSuccessAction({
              couponData,
            });
          }),
          catchError((error: any) => {
            this._notifications.push({
              title: this._translate.instant('error'),
              content: this._translate.instant('notifications.load_failure', {
                param: this._translate.instant('coupon'),
              }),
              type: 'error',
            });
            return of(new featureActions.LoadDetailsFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  updateRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.UpdateRequestAction>(
        featureActions.ActionTypes.UPDATE_REQUEST,
      ),
      switchMap((action: featureActions.UpdateRequestAction) => {
        const { newCouponData, propertyId, couponId } = action.payload;
        return this.dataService
          .update(propertyId, couponId, newCouponData)
          .pipe(
            map(() => {
              this._notifications.push({
                title: this._translate.instant('done'),
                content: this._translate.instant(
                  'notifications.update_success',
                  {
                    param: this._translate.instant('coupon'),
                  },
                ),
                type: 'success',
              });
              return new featureActions.UpdateSuccessAction();
            }),
            catchError((error: any) => {
              this._notifications.push({
                title: this._translate.instant('error'),
                content: this._translate.instant(
                  'notifications.update_failure',
                  {
                    param: this._translate.instant('coupon'),
                  },
                ),
                type: 'error',
              });
              return of(new featureActions.UpdateFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  createRequestEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.CreateRequestAction>(
        featureActions.ActionTypes.CREATE_REQUEST,
      ),
      switchMap((action: featureActions.CreateRequestAction) => {
        const { newCouponData, propertyId } = action.payload;
        return this.dataService.create(propertyId, newCouponData).pipe(
          map(() => {
            this._notifications.push({
              title: this._translate.instant('done'),
              content: this._translate.instant('notifications.create_success', {
                param: this._translate.instant('coupon'),
              }),
              type: 'success',
            });

            this._router.navigate([
              '/rates-and-availabilities/rates-and-offers/coupons',
            ]);
            return new featureActions.CreateSuccessAction();
          }),
          catchError((error: any) => {
            this._notifications.push({
              title: this._translate.instant('error'),
              content: this._translate.instant('notifications.create_failure', {
                param: this._translate.instant('coupon'),
              }),
              type: 'error',
            });
            return of(new featureActions.CreateFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  deleteRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.DeleteRequestAction>(
        featureActions.ActionTypes.DELETE_REQUEST,
      ),
      switchMap((action: featureActions.DeleteRequestAction) =>
        this.dataService
          .delete(action.payload.propertyId, action.payload.couponId)
          .pipe(
            map(
              () =>
                new featureActions.DeleteSuccessAction({
                  couponId: action.payload.couponId,
                }),
            ),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(new featureActions.DeleteFailureAction({ error }));
            }),
          ),
      ),
    ),
  );

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),
      switchMap((action: featureActions.LoadRequestAction) =>
        this.dataService.load(action.payload.propertyIds).pipe(
          map(
            ({ data }: any) =>
              new featureActions.LoadSuccessAction({
                items: data,
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        ),
      ),
    ),
  );
}
