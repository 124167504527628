import { Action } from '@ngrx/store';

import { Coupon } from '../../models';

export enum ActionTypes {
  LOAD_REQUEST = '[Coupons] Load Request',
  LOAD_FAILURE = '[Coupons] Load Failure',
  LOAD_SUCCESS = '[Coupons] Load Success',

  DELETE_REQUEST = '[Coupons] Delete Request',
  DELETE_FAILURE = '[Coupons] Delete Failure',
  DELETE_SUCCESS = '[Coupons] Delete Success',

  CREATE_REQUEST = '[Coupons] Create Request',
  CREATE_FAILURE = '[Coupons] Create Failure',
  CREATE_SUCCESS = '[Coupons] Create Success',

  UPDATE_REQUEST = '[Coupons] Update Request',
  UPDATE_FAILURE = '[Coupons] Update Failure',
  UPDATE_SUCCESS = '[Coupons] Update Success',

  LOAD_DETAILS_REQUEST = '[Coupons] Load Details Request',
  LOAD_DETAILS_FAILURE = '[Coupons] Load Details Failure',
  LOAD_DETAILS_SUCCESS = '[Coupons] Load Details Success',

  RESET_STATE = '[Coupons] Reset State',
}

export class LoadDetailsRequestAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_REQUEST;
  constructor(public payload: { propertyId: number; couponId: number }) {}
}

export class LoadDetailsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_FAILURE;
  constructor(public payload: { error: Error }) {}
}

export class LoadDetailsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DETAILS_SUCCESS;
  constructor(public payload: { couponData: Coupon }) {}
}

export class UpdateRequestAction implements Action {
  readonly type = ActionTypes.UPDATE_REQUEST;
  constructor(
    public payload: {
      propertyId: number;
      newCouponData: Coupon;
      couponId: number;
    },
  ) {}
}

export class UpdateFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_SUCCESS;
}

export class CreateRequestAction implements Action {
  readonly type = ActionTypes.CREATE_REQUEST;
  constructor(public payload: { propertyId: number; newCouponData: Coupon }) {}
}

export class CreateFailureAction implements Action {
  readonly type = ActionTypes.CREATE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class CreateSuccessAction implements Action {
  readonly type = ActionTypes.CREATE_SUCCESS;
}
export class DeleteRequestAction implements Action {
  readonly type = ActionTypes.DELETE_REQUEST;
  constructor(public payload: { propertyId: number; couponId: number }) {}
}

export class DeleteFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class DeleteSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUCCESS;
  constructor(public payload: { couponId: number }) {}
}
export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: { propertyIds: number[] }) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(public payload: { items: Coupon[] }) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ResetSuccessAction
  | DeleteRequestAction
  | DeleteFailureAction
  | DeleteSuccessAction
  | CreateRequestAction
  | CreateSuccessAction
  | CreateFailureAction
  | UpdateRequestAction
  | UpdateSuccessAction
  | UpdateFailureAction
  | LoadDetailsRequestAction
  | LoadDetailsSuccessAction
  | LoadDetailsFailureAction;
